<template>
	<div>
	  <div class="px-4 pt-4 w-100 d-flex align-center justify-space-between">
		<div>
			<v-form class="d-flex align-center" v-model="formValid" ref="form" lazy-validation @submit.prevent="blockUserId()">
			  <div class="px-2 fsize14 ">
				<div class="pb-1">User ID</div>
				<v-text-field outlined dense placeholder="Enter the UserId" v-model="userID" :rules="userIdRules"></v-text-field>
			  </div>
  
			  <div class="px-2 d-flex justify-end mb-2 mt-2">
				<v-btn type="submit" :loading="getLoader" :disabled="getLoader" height="40" color="primary" depressed class="text-capitalize fsize14">Un-Block</v-btn>
			  </div>
  
			 
			</v-form>
  
	 
		</div>
	  </div>
	</div>
  </template>
  
  <!-- <style>
  .snoWidth {
	width: 25%;
  }
  .dateWidth {
	width: 75%;
  }
  
  </style> -->
  <script >
  import { mapGetters } from "vuex"
  const editSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mx-auto">
	<path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  </svg>
  `
  export default {
	data() { 
	  return { 
		userID: '',
		submitted : false,
		userIdRules: [(v) => !!v || "userID is required"],
		formValid: true,
	  }
	}, 
	computed: {
    ...mapGetters('user', ['getLoader'])
  },
	methods: {
		async blockUserId() {
		if (this.$refs.form.validate()) { 
		  let json = {
			"userIds": this.userID ? this.userID.toString().trim().split(',') : this.userID
		  }
		  this.$store.dispatch("user/userUnBlock",json) 
		}
	  },
	 
	},
	created() {
	  this.$store.commit('user/setUserDetails', [])
	},
  }
  </script>
  